import { LINKS, LinkType } from "@components/Link/Link";
import { Link } from "gatsby";
import React from "react";
import { TypographyV2 } from "..";
import * as styles from "./CardWithIcon.module.scss";
import { Weight } from "../TypographyV2/TypographyV2";

export type CardWithIconProps = {
	icon: JSX.Element;
	title: string;
	description: string;
	to: LinkType;
	Cta: string;
};

const CardWithIcon = ({
	icon,
	title,
	description,
	to,
	Cta,
}: CardWithIconProps) => {
	return (
		<div className={styles.card}>
			<section className={styles.upperSection}>
				<div className={styles.icon}>{icon}</div>
				<TypographyV2
					variant="BODY_TEXT_LARGE"
					color="text-primary"
					weight="SEMIBOLD"
					// mobileVariant="BODY_TEXT_LARGE"
					style={{ margin: 0 }}
					className={styles.title}
				>
					{title}
				</TypographyV2>
				<TypographyV2
					className={styles.description}
					variant="BODY_TEXT_LARGE"
					color="text-helper"
					weight="REGULAR"
					mobileVariant="BODY_TEXT_LARGE"
					style={{ margin: 0 }}
				>
					{description}
				</TypographyV2>
			</section>
			<Link to={LINKS[to].to} style={{ marginTop: "auto" }}>
				<div className={styles.button}>
					<TypographyV2
						weight="SEMIBOLD"
						variant="BODY_TEXT_LARGE"
						color="brand-500"
						className={styles.Cta}
					>
						{Cta}
					</TypographyV2>
					<svg
						width={28}
						height={29}
						viewBox="0 0 28 29"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.8335 14.5419H22.1668M22.1668 14.5419L14.0002 6.37524M22.1668 14.5419L14.0002 22.7086"
							stroke="#051D85"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</Link>
		</div>
	);
};

export default CardWithIcon;
