import React, { FC } from "react";
import CardWithIcon, {
	CardWithIconProps,
} from "@components/CardWithIcon/CardWithIcon";
import * as styles from "./LabConsumablesCards.module.scss";
import { TypographyV2 } from "@components/index";
import { Link } from "gatsby";
import { LINKS } from "@components/Link/Link";
import classNames from "classnames";

interface LabConsumablesCardsProps {
	cards: CardWithIconProps[];
	hideSignUpButton?: boolean;
	sectionWrapperClassName?: string;
	btnVariant?: "light" | "dark";
}

const LabConsumablesCards: FC<LabConsumablesCardsProps> = (props) => {
	const buttonClassName =
		props.btnVariant === "light" ? styles.light_button : styles.button;
	return (
		<div
			className={classNames(
				styles.content,
				props?.sectionWrapperClassName
			)}
		>
			<div className={styles.text}>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					className={styles.title}
				>
					The Modern R&D platform that can scale with your custom or
					high-volume needs
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_LARGE_V2"
					color="text-helper"
					className={styles.centeredText}
				>
					Tailor Genemod for site-wide licenses of any scale
				</TypographyV2>
			</div>
			<div className={styles.cards}>
				{(props.cards ?? [])?.map((card, index) => {
					return <CardWithIcon {...card} key={index} />;
				})}
			</div>
			{!props?.hideSignUpButton && (
				<Link
					to={LINKS.SIGN_UP.to}
					className={classNames(buttonClassName, styles.button)}
				>
					<TypographyV2
						variant="BODY_TEXT_LARGE"
						weight="SEMIBOLD"
						color={
							props.btnVariant === "light"
								? "brand-500"
								: "base-white"
						}
						style={{ margin: 0 }}
					>
						Sign up for free
					</TypographyV2>
				</Link>
			)}
		</div>
	);
};

export default LabConsumablesCards;
