// extracted by mini-css-extract-plugin
export var BODY_TEXT_EXTRA_LARGE = "LabConsumablesCards-module--BODY_TEXT_EXTRA_LARGE--vB5-r";
export var BODY_TEXT_EXTRA_SMALL = "LabConsumablesCards-module--BODY_TEXT_EXTRA_SMALL--QTCoX";
export var BODY_TEXT_LARGE = "LabConsumablesCards-module--BODY_TEXT_LARGE--rYk8s";
export var BODY_TEXT_LARGE_V2 = "LabConsumablesCards-module--BODY_TEXT_LARGE_V2--JCbpI";
export var BODY_TEXT_MEDIUM = "LabConsumablesCards-module--BODY_TEXT_MEDIUM--7Ww7M";
export var BODY_TEXT_MEDIUM_V1 = "LabConsumablesCards-module--BODY_TEXT_MEDIUM_V1--2vlPo";
export var BODY_TEXT_SMALL = "LabConsumablesCards-module--BODY_TEXT_SMALL--WoFyR";
export var BOLD = "LabConsumablesCards-module--BOLD--xy7ok";
export var COMMON = "LabConsumablesCards-module--COMMON--Bj2Mf";
export var FONT_FIGTREE = "LabConsumablesCards-module--FONT_FIGTREE--OeDXI";
export var HEADING = "LabConsumablesCards-module--HEADING--paNd3";
export var HEADING_1 = "LabConsumablesCards-module--HEADING_1--xrdhE";
export var HEADING_1_V1 = "LabConsumablesCards-module--HEADING_1_V1--e8Kmq";
export var HEADING_2 = "LabConsumablesCards-module--HEADING_2--2w0cE";
export var HEADING_2_V2 = "LabConsumablesCards-module--HEADING_2_V2--2m0+P";
export var HEADING_3 = "LabConsumablesCards-module--HEADING_3--g65qW";
export var HEADING_4 = "LabConsumablesCards-module--HEADING_4--jmEaL";
export var HEADING_4_V2 = "LabConsumablesCards-module--HEADING_4_V2--g-fFO";
export var HEADING_5 = "LabConsumablesCards-module--HEADING_5--ZldaZ";
export var HEADING_5_V2 = "LabConsumablesCards-module--HEADING_5_V2--zjvlu";
export var HEADING_GENERALSANS = "LabConsumablesCards-module--HEADING_GENERALSANS--uZrrH";
export var MEDIUM = "LabConsumablesCards-module--MEDIUM--VIXKd";
export var MOBILE_BODY_TEXT_EXTRA_LARGE = "LabConsumablesCards-module--MOBILE_BODY_TEXT_EXTRA_LARGE--btIDj";
export var MOBILE_BODY_TEXT_EXTRA_SMALL = "LabConsumablesCards-module--MOBILE_BODY_TEXT_EXTRA_SMALL--drxXj";
export var MOBILE_BODY_TEXT_LARGE = "LabConsumablesCards-module--MOBILE_BODY_TEXT_LARGE--Rz2Yo";
export var MOBILE_BODY_TEXT_LARGE_V2 = "LabConsumablesCards-module--MOBILE_BODY_TEXT_LARGE_V2--GYzNQ";
export var MOBILE_BODY_TEXT_MEDIUM = "LabConsumablesCards-module--MOBILE_BODY_TEXT_MEDIUM--GxmrQ";
export var MOBILE_BODY_TEXT_MEDIUM_V1 = "LabConsumablesCards-module--MOBILE_BODY_TEXT_MEDIUM_V1--macLN";
export var MOBILE_BODY_TEXT_SMALL = "LabConsumablesCards-module--MOBILE_BODY_TEXT_SMALL--Te9+-";
export var MOBILE_HEADING_1 = "LabConsumablesCards-module--MOBILE_HEADING_1--mX4gw";
export var MOBILE_HEADING_1_V1 = "LabConsumablesCards-module--MOBILE_HEADING_1_V1--8Dx3l";
export var MOBILE_HEADING_2 = "LabConsumablesCards-module--MOBILE_HEADING_2--IGRO4";
export var MOBILE_HEADING_2_V2 = "LabConsumablesCards-module--MOBILE_HEADING_2_V2--LWskE";
export var MOBILE_HEADING_3 = "LabConsumablesCards-module--MOBILE_HEADING_3--SzR2b";
export var MOBILE_HEADING_4 = "LabConsumablesCards-module--MOBILE_HEADING_4--l4RLj";
export var MOBILE_HEADING_4_V2 = "LabConsumablesCards-module--MOBILE_HEADING_4_V2--uN4oG";
export var MOBILE_HEADING_5 = "LabConsumablesCards-module--MOBILE_HEADING_5--L7Aq3";
export var MOBILE_HEADING_5_V2 = "LabConsumablesCards-module--MOBILE_HEADING_5_V2--4luQG";
export var REGULAR = "LabConsumablesCards-module--REGULAR--f-MvN";
export var SEMIBOLD = "LabConsumablesCards-module--SEMIBOLD--DsaJW";
export var TABLET_BODY_TEXT_EXTRA_LARGE = "LabConsumablesCards-module--TABLET_BODY_TEXT_EXTRA_LARGE--NXXpK";
export var TABLET_BODY_TEXT_EXTRA_SMALL = "LabConsumablesCards-module--TABLET_BODY_TEXT_EXTRA_SMALL--3dJyp";
export var TABLET_BODY_TEXT_LARGE = "LabConsumablesCards-module--TABLET_BODY_TEXT_LARGE--bgVia";
export var TABLET_BODY_TEXT_LARGE_V2 = "LabConsumablesCards-module--TABLET_BODY_TEXT_LARGE_V2---1waw";
export var TABLET_BODY_TEXT_MEDIUM = "LabConsumablesCards-module--TABLET_BODY_TEXT_MEDIUM--rRcRD";
export var TABLET_BODY_TEXT_MEDIUM_V1 = "LabConsumablesCards-module--TABLET_BODY_TEXT_MEDIUM_V1--YqpHU";
export var TABLET_BODY_TEXT_SMALL = "LabConsumablesCards-module--TABLET_BODY_TEXT_SMALL--Z2+jX";
export var TABLET_HEADING_1 = "LabConsumablesCards-module--TABLET_HEADING_1---pYzy";
export var TABLET_HEADING_1_V1 = "LabConsumablesCards-module--TABLET_HEADING_1_V1--6XLPg";
export var TABLET_HEADING_2 = "LabConsumablesCards-module--TABLET_HEADING_2--LLoC7";
export var TABLET_HEADING_2_V2 = "LabConsumablesCards-module--TABLET_HEADING_2_V2--c3+Dr";
export var TABLET_HEADING_3 = "LabConsumablesCards-module--TABLET_HEADING_3--bWGex";
export var TABLET_HEADING_4 = "LabConsumablesCards-module--TABLET_HEADING_4--TE-Am";
export var TABLET_HEADING_4_V2 = "LabConsumablesCards-module--TABLET_HEADING_4_V2--w3Zpn";
export var TABLET_HEADING_5 = "LabConsumablesCards-module--TABLET_HEADING_5--diN4F";
export var TABLET_HEADING_5_V2 = "LabConsumablesCards-module--TABLET_HEADING_5_V2--BOQM-";
export var button = "LabConsumablesCards-module--button--9+C77";
export var cards = "LabConsumablesCards-module--cards--Q7im6";
export var center = "LabConsumablesCards-module--center--H7eAy";
export var centeredText = "LabConsumablesCards-module--centeredText--DePU0";
export var content = "LabConsumablesCards-module--content--BkIH3";
export var disabled = "LabConsumablesCards-module--disabled--f6h4+";
export var layout = "LabConsumablesCards-module--layout--6318C";
export var learnMoreDefaultPadding = "LabConsumablesCards-module--learnMoreDefaultPadding--Dmnrl";
export var light_button = "LabConsumablesCards-module--light_button--GOscm";
export var limitWidthOnLargeScreens = "LabConsumablesCards-module--limitWidthOnLargeScreens--hmFdM";
export var link = "LabConsumablesCards-module--link--LytLS";
export var mobileWidth = "LabConsumablesCards-module--mobileWidth--ygeF0";
export var secondary = "LabConsumablesCards-module--secondary--QUIhu";
export var tabletFullWidth = "LabConsumablesCards-module--tabletFullWidth--RKJ2c";
export var text = "LabConsumablesCards-module--text--WcjMb";
export var title = "LabConsumablesCards-module--title--eNT+3";
export var v2 = "LabConsumablesCards-module--v2--xEf39";